import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, Button, Flex } from 'antd'
import './index.css'
import { ModuleTopHeading } from '../PageComponents/ModuleTopHeading'

const { Title, Text } = Typography

const CardComponent = () => {

    const [ current, setCurrent ] = useState(0)

    const data = [
        {
            title:'0.00 Peso',
            subtitle:'Commission',
            img:'win.png',
        },
        {
            title:'0.00 Peso',
            subtitle:'Balance',
            img:'balance.png',
        },  
        {
            title:'0.00',
            subtitle:'Downline master agents',
            img:'agent-1.png',
        },       
        {
            title:'0.00',
            subtitle:'Downline agents',
            img:'agent.png',
        },        
        {
            title:'0.00',
            subtitle:'Downline players',
            img:'player.png',
        },
    ]

  return (
    <div>
        <Card className='card-bg border0 py-3'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <ModuleTopHeading 
                        name='Overview of CM activities'
                    />
                </Col>

                {
                    data?.map((condata,index)=>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                            <Card className='bg-main border0 rounded height-100'>
                                <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                    <div className='my-2'>
                                        <Text className='text-white' strong>{condata?.subtitle}</Text>
                                        <Title level={3} className='text-white my-0'>{condata?.title}</Title>
                                    </div>
                                    <Image src={'/assets/icons/'+condata?.img} width={'40px'} />
                                </Space>
                            </Card>  
                        </Col>
                    )
                }
            </Row>
        </Card>
    </div>
  )
}

export {CardComponent}