import React from 'react'
import { Card, Col, Flex, Image, Row, Typography } from 'antd'

const { Title, Text } = Typography

const ReferralCards = () => {

    const data = [
        {
            title:'1000',
            subtitle:'Successful referrals',
            img:'ref.png',
        },
        {
            title:'1000',
            subtitle:'Total successful referrals',
            img:'totalref.png',
        },
    ]

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                {   
                    data?.map((condata,index)=>
                        <Col xs={24} sm={24} md={12} key={index}>
                            <Card className='bg-main border0 rounded'>
                                <Flex size={80} className='w-100' justify='space-between' align='center'>
                                    <div className='my-2'>
                                        <Text className='text-white' strong>{condata?.subtitle}</Text>
                                        <Title level={3} className='text-white my-0'>{condata?.title}</Title>
                                    </div>
                                    <Image src={'/assets/icons/'+condata?.img} width={'40px'} />
                                </Flex>
                            </Card>  
                        </Col>
                    )
                }
            </Row>
        </Card>
    </div>
  )
}

export {ReferralCards}