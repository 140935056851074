import React from 'react'
import { Button, Col, DatePicker, Flex, Form, Row, Typography } from 'antd'
import { MyInput } from '../../Forms'
import { Casselect } from '../../Casselect'

const { Text } = Typography

const ProfileSetting = () => {

    const [form] = Form.useForm()

  return (
    <div>
        <Form
            layout="vertical"
            form={form}
            initialValues={{
                remember: true,
            }}
            onFinish={()=>{}}
        >
            <Row gutter={16}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='firstName'
                        label='First name'
                        required
                        message='Please enter your first name'
                        placeholder='  jhone'
                        value={form.getFieldValue("firstName") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='midName'
                        label='Middle name'
                        required
                        message='Please enter your middle name'
                        placeholder='  kevin'
                        value={form.getFieldValue("midName") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='lastName'
                        label='Last name'
                        required
                        message='Please enter your last name'
                        placeholder='  kevin'
                        value={form.getFieldValue("lastName") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='username'
                        label='Username'
                        disabled
                        placeholder='  kevin'
                        value={form.getFieldValue("username") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='password'
                        label='Password'
                        disabled
                        placeholder='  A@123456'
                        value={form.getFieldValue("password") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item
                        name='dob'
                        label={<Text className='text-white'>DoB</Text>}
                        rules={[
                            {
                                required:true,
                                message:'Please enter dob'
                            }
                        ]}
                        >
                        <DatePicker 
                            className='bg-main border0 w-100 searchinput pad-input'
                            placeholder='12/03/2024'
                            value={form.getFieldValue("dob") || ''}
                            allowClear={false}
                            inputReadOnly
                        />
                    </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Casselect
                        label= 'Gender'
                        name="gender" 
                        required
                        message='Please choose gender'
                        placeholder='male'
                        value={form.getFieldValue("gender")}
                        className='text-white'
                        options={[
                            {name:"Male", id:'male'},
                            {name:"Female", id:'female'},
                            {name:"Other", id:'other'},
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Flex justify='end'>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn"
                        >
                            Save Changes
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

export {ProfileSetting}