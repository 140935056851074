import React, { useState } from 'react'
import { Flex, Modal, Form, Button, Space } from 'antd'
import { MyInput } from '../../Forms';

const DeductBalanceMasterModal = ({deductbalance,onClose}) => {
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false)

  return (
    <div>
        <Modal width={500} 
            title='Deduct balance'
            className='modalapply'  
            open={deductbalance} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={null}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    remember: true,
                }}
                requiredMark={false}
                // onFinish={onFinish}
            >
                <Flex vertical gap={'large'}>
                    <MyInput
                        name='deductBalance'
                        required
                        message='Please enter deduct balance'
                        placeholder='e.g 1200$'
                        value={form.getFieldValue("deductBalance") || ''} 
                    />
                    <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btn-cancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            danger
                            loading={loading}
                            className='bg-gradient border0'
                            // onClick={()=>{
                            //     DeleteProductApi();
                            // }}
                        >
                            Save
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Modal>
    </div>
  )
}

export {DeductBalanceMasterModal}