import React, { useState } from 'react'
import { Drawer, Form, Row, Col, Typography, DatePicker} from "antd"
import { Casselect, MyInput } from '../../../components';
import { TransactionSpecificPlayer } from './TransactionSpecificPlayer';
import { CalendarOutlined } from '@ant-design/icons';

const { Text } = Typography

const ViewPlayerDetailDrawer = ({viewvisible,onClose}) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    return (
        <div>
            <Drawer
                title={<Text className='text-white'>View Player</Text>}
                onClose={onClose}
                className="card-bg"
                open={viewvisible}
                width={900}
                footer={false}
            >
                <Row gutter={[24,24]}>
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                        >
                            <Row gutter={16}>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='firstName'
                                        label='First name'
                                        placeholder='  jhon'
                                        value={form.getFieldValue("firstName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='midName'
                                        label='Middle name'
                                        placeholder='  jhon'
                                        value={form.getFieldValue("midName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='lastName'
                                        label='Last name'
                                        placeholder='  jhon'
                                        value={form.getFieldValue("lastName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='userName'
                                        label='Username'
                                        placeholder='  jhon89'
                                        value={form.getFieldValue("userName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='password'
                                        type='password'
                                        label='Password'
                                        className='bg-main border0'
                                        placeholder='  A@123456'
                                        value={form.getFieldValue("password") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Form.Item
                                        name='dob'
                                        label={<Text className='text-white'>DoB</Text>}
                                        >
                                        <DatePicker 
                                            className='bg-main border0 w-100 searchinput pad-input'
                                            placeholder='12/03/2024'
                                            value={form.getFieldValue("dob") || ''}
                                            allowClear={false}
                                            inputReadOnly
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Casselect
                                        label= 'Gender'
                                        name="gender" 
                                        placeholder='male'
                                        value={form.getFieldValue("gender")}
                                        className='text-white'
                                        options={[
                                            {name:"Male", id:'male'},
                                            {name:"Female", id:'female'},
                                            {name:"Other", id:'other'},
                                        ]}
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Casselect
                                        label= 'Master agent'
                                        name="masterAgent" 
                                        placeholder='Shujat ali'
                                        value={form.getFieldValue("masterAgent")}
                                        className='text-white'
                                        options={[
                                            {name:"Shujat", id:'shujat'},
                                            {name:"Sheraz", id:'sheraz'},
                                            {name:"Ali", id:'ali'},
                                        ]}
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Casselect
                                        label= 'Agent'
                                        name="agent" 
                                        placeholder='Ali'
                                        value={form.getFieldValue("agent")}
                                        className='text-white'
                                        options={[
                                            {name:"Shujat", id:'shujat'},
                                            {name:"Sheraz", id:'sheraz'},
                                            {name:"Ali", id:'ali'},
                                        ]}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <TransactionSpecificPlayer />            
                    </Col>                    
                </Row>
            </Drawer>
        </div>
    )
}

export {ViewPlayerDetailDrawer}