import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Space, Switch, Dropdown } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, AlertModal, AddPlayerDrawer, MyInputnoLabel } from '../../../components';
import { CreditCardOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ViewPlayerDetailDrawer } from '../ViewPlayerDetailDrawer';
import { AddCreditPlayerModal } from '../AddCreditPlayerModal';
import { AddBalancePlayerModal } from '../AddBalancePlayerModal';
import { DeductBalancePlayerModal } from '../DeductBalancePlayerModal';

const { Text } = Typography

const PlayerDetailTable = () => {

    const [form] = Form.useForm();
    const [ visible, setVisible ]= useState(false);
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [ editplayer, setEditPlayer ] = useState(null)
    const [ viewvisible, setViewVisible ] = useState(false)
    const [ addcredit, setAddCredit ] = useState(false)
    const [ deductbalance, setDeductBalance ] = useState(false)
    const [ addbalance, setAddBalance ] = useState(false)
    
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };

      const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];
  
      const columns = [
            {
                title: <Text className='text-white'>Player ID</Text>,
                dataIndex: 'playerId',
                width: 150,
            },
            {
                title: <Text className='text-white'>Player name</Text>,
                dataIndex: 'playerName',
                width: 150,
            },
            {
                title: <Text className='text-white'>Username</Text>,
                dataIndex: 'userName',
                width: 150,
            },
            {
                title: <Text className='text-white'>DoB</Text>,
                dataIndex: 'dob',
                width: 150,
            },
            {
                title: <Text className='text-white'>Gender</Text>,
                dataIndex: 'gender',
                width: 150,
            },
            {
                title: <Text className='text-white'>Time stamp</Text>,
                dataIndex: 'timeStamp',
                width: 200,
            },
            {
                title: <Text className='text-white'>Action</Text>,
                key: 'action',
                fixed: 'right',
                width: 220,
                render: (_, row) => (
                    <Space>
                        <ActionButton
                            title='View player'
                            shape="circle"
                            icon={<EyeOutlined className='text-white' />}
                            onClick={() => { setViewVisible(true) }}
                            ghost
                            className='borderwhite'
                        />
                        <ActionButton
                            title='Edit player'
                            shape="circle"
                            icon={<EditOutlined className='text-white' />}
                            onClick={() => { setVisible(true);setEditPlayer(row?.playerId)}}
                            ghost
                            className='borderwhite'
                        />
                        <ActionButton
                            title='Credit'
                            shape="circle"
                            icon={<CreditCardOutlined className='text-white' />}
                            onClick={() => {setAddCredit(true)}}
                            ghost
                            className='borderwhite'
                        />
                        <ActionButton
                            title='Add balance'
                            shape="circle"
                            icon={<PlusOutlined className='text-white' />}
                            onClick={() => {setAddBalance(true)}}
                            ghost
                            className='borderwhite'
                        />
                        <ActionButton
                            title='Deduct balance'
                            shape="circle"
                            icon={<MinusOutlined className='text-white' />}
                            onClick={() => {setDeductBalance(true)}}
                            ghost
                            className='borderwhite'
                        />
                        <Switch defaultChecked size='small' onChange={onChange} />
                    </Space>
                ),
            },
        ];
  
        const data = [
            {
                key: '1',
                playerId: <Text className='text-white'>01</Text>,
                playerName: <Text className='text-white'>Anil</Text>,
                userName: <Text className='text-white'>anil@gmail.com</Text>,
                dob: <Text className='text-white'>12/12/2000</Text>,
                gender: <Text className='text-white'>Male</Text>,
                timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
            },
            {
                key: '2',
                playerId: <Text className='text-white'>02</Text>,
                playerName: <Text className='text-white'>Anil</Text>,
                userName: <Text className='text-white'>anil@gmail.com</Text>,
                dob: <Text className='text-white'>12/12/2000</Text>,
                gender: <Text className='text-white'>Male</Text>,
                timeStamp: <Text className='text-white'>12/12/2023 10:00 PM</Text>,
            },
            {
                key: '3',
                playerId: <Text className='text-white'>03</Text>,
                playerName: <Text className='text-white'>Anil</Text>,
                userName: <Text className='text-white'>anil@gmail.com</Text>,
                dob: <Text className='text-white'>12/12/2000</Text>,
                gender: <Text className='text-white'>Male</Text>,
                timeStamp: <Text className='text-white'>01/07/2023 07:00 PM</Text>,
            },
            {
                key: '4',
                playerId: <Text className='text-white'>04</Text>,
                playerName: <Text className='text-white'>Anil</Text>,
                userName: <Text className='text-white'>anil@gmail.com</Text>,
                dob: <Text className='text-white'>12/12/2000</Text>,
                gender: <Text className='text-white'>Male</Text>,
                timeStamp: <Text className='text-white'>21/04/2024 04:00 PM</Text>,
            },
            {
                key: '5',
                playerId: <Text className='text-white'>05</Text>,
                playerName: <Text className='text-white'>Anil</Text>,
                userName: <Text className='text-white'>anil@gmail.com</Text>,
                dob: <Text className='text-white'>12/12/2000</Text>,
                gender: <Text className='text-white'>Male</Text>,
                timeStamp: <Text className='text-white'>18/06/2024 03:00 PM</Text>,
            },
        ];
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='Players'
                            onClick={()=>{setVisible(true)}}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
                <AddPlayerDrawer
                    visible={visible}
                    editplayer={editplayer}
                    onClose={()=> {setVisible(false);setEditPlayer(null)}}
                />
                <ViewPlayerDetailDrawer 
                    viewvisible={viewvisible}
                    onClose={()=>setViewVisible(false)}
                />
                <AlertModal 
                    visiblemodal={visiblemodal}
                    onClose={()=>setVisibleModal(false)}
                />
                <AddCreditPlayerModal 
                    addcredit={addcredit}
                    onClose={()=>setAddCredit(false)}
                />
                <AddBalancePlayerModal 
                    addbalance={addbalance}
                    onClose={()=>setAddBalance(false)}
                />
                <DeductBalancePlayerModal 
                    deductbalance={deductbalance}
                    onClose={()=>setDeductBalance(false)}
                />
            </Card>
        </div>
    )
}

export {PlayerDetailTable}