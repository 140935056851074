import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Space, Switch, Dropdown } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, AlertModal, AgentDrawer, ApplyAgentModal, ViewAgentDetailDrawer, MyInputnoLabel } from '../../../components'
import { CreditCardOutlined, EditOutlined, EyeOutlined, FilterOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { AddBalanceAgentModal } from '../AddBalanceAgentModal'
import { AddCreditagentModal } from '../AddCreditagentModal'
import { DeductBalanceAgentModal } from '../DeductBalanceAgentModal'

const { Text } = Typography

const AgentDetailTable = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ]= useState(false);
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [ agentedit, setAgentEdit ] = useState(null)
    const [ applyagent, setApplyAgent ] = useState(false)
    const [ viewvisible, setViewVisible ] = useState(false)
    const [ addcredit, setAddCredit ] = useState(false)
    const [ deductbalance, setDeductBalance ] = useState(false)
    const [ addbalance, setAddBalance ] = useState(false)

    const onChange = (checked) => {
        if(!checked){
            setApplyAgent(true)
        }
        console.log(`switch to ${checked}`);
    };

    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];
  
    const columns = [
        {
            title: <Text className='text-white'>ID</Text>,
            dataIndex: 'agentId',
        },
        {
            title: <Text className='text-white'>Name</Text>,
            dataIndex: 'name',
        },
        {
            title: <Text className='text-white'>Username</Text>,
            dataIndex: 'userName',
        },
        {
            title: <Text className='text-white'>DoB</Text>,
            dataIndex: 'dob',
        },
        {
            title: <Text className='text-white'>Gender</Text>,
            dataIndex: 'gender',
        },
        {
            title: <Text className='text-white'>Comission rate</Text>,
            dataIndex: 'comissionRate',
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width: 200
        },
        {
            title: <Text className='text-white'>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 220,
            render: (_, row) => (
                <Flex gap={'small'} align='center'>
                    <ActionButton
                        title='View agent'
                        shape="circle"
                        icon={<EyeOutlined className='text-white' />}
                        onClick={() => { setViewVisible(true) }}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Edit manager'
                        shape="circle"
                        icon={<EditOutlined className='text-white' />}
                        onClick={() => { setVisible(true);setAgentEdit(row?.agentId)}}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Credit'
                        shape="circle"
                        icon={<CreditCardOutlined className='text-white' />}
                        onClick={() => {setAddCredit(true)}}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Add balance'
                        shape="circle"
                        icon={<PlusOutlined className='text-white' />}
                        onClick={() => {setAddBalance(true)}}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Deduct balance'
                        shape="circle"
                        icon={<MinusOutlined className='text-white' />}
                        onClick={() => {setDeductBalance(true)}}
                        ghost
                        className='borderwhite'
                    />
                    <Switch defaultChecked size='small' onChange={onChange} />
                </Flex>
            ),
        },
    ];


    const data = [
        {
            key: '1',
            agentId: <Text className='text-white'>01</Text>,
            name: <Text className='text-white'>Zahid Imam</Text>,
            userName: <Text className='text-white'>zahid998</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>Male</Text>,
            comissionRate: <Text className='text-white'>200$</Text>,
            timeStamp: <Text className='text-white'>12/12/2023 12:00 AM</Text>,
        },
        {
            key: '2',
            agentId: <Text className='text-white'>01</Text>,
            name: <Text className='text-white'>Zahid Imam</Text>,
            userName: <Text className='text-white'>zahid998</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>Male</Text>,
            comissionRate: <Text className='text-white'>200$</Text>,
            timeStamp: <Text className='text-white'>12/12/2023 10:00 PM</Text>,
        },
        {
            key: '3',
            agentId: <Text className='text-white'>01</Text>,
            name: <Text className='text-white'>Zahid Imam</Text>,
            userName: <Text className='text-white'>zahid998</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>Male</Text>,
            comissionRate: <Text className='text-white'>200$</Text>,
            timeStamp: <Text className='text-white'>01/07/2023 07:00 PM</Text>,
        },

      ];
  
    
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='Agent'
                            onClick={()=>{setVisible(true)}}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
                <AgentDrawer
                    visible={visible}
                    agentedit={agentedit}
                    onClose={()=> {setVisible(false);setAgentEdit(null)}}
                />
                <ViewAgentDetailDrawer 
                    viewvisible={viewvisible}
                    onClose={()=>setViewVisible(false)}
                />
                <ApplyAgentModal 
                    applyagent={applyagent}
                    onClose={()=>setApplyAgent(false)}
                />
                <AlertModal 
                    visiblemodal={visiblemodal}
                    onClose={()=>setVisibleModal(false)}
                />

                <AddCreditagentModal 
                    addcredit={addcredit}
                    onClose={()=>setAddCredit(false)}
                />
                            
                <AddBalanceAgentModal 
                    addbalance={addbalance}
                    onClose={()=>setAddBalance(false)}
                />

                <DeductBalanceAgentModal 
                    deductbalance={deductbalance}
                    onClose={()=>setDeductBalance(false)}
                />
            </Card>
        </div>
    )
}

export {AgentDetailTable}