import React, { useState } from 'react'
import { Flex, Modal, Form, Button, Space } from 'antd'
import { Casselect } from '../../Casselect'
import './index.css'

const ApplyCitymanagerModal = ({applyentity,onClose}) => {

    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false)

  return (
    <div>
        <Modal width={500} 
            title='Assign master agent'
            className='modalapply'  
            open={applyentity} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={null}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    remember: true,
                }}
                requiredMark={false}
                // onFinish={onFinish}
            >
                <Flex vertical gap={'large'}>
                    <Casselect
                        name="masterAgent" 
                        required
                        message='Please choose master agent'
                        placeholder='Select master agent'
                        value={form.getFieldValue("masterAgent")}
                        className='text-white'
                        options={[
                            {name:"Shujat", id:'shujat'},
                            {name:"Sheraz", id:'sheraz'},
                            {name:"Ali", id:'ali'},
                        ]}
                    />
                    <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                        <Button
                            onClick={onClose}
                            className='btn-cancel'
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            danger
                            loading={loading}
                            className='bg-gradient border0'
                            // onClick={()=>{
                            //     DeleteProductApi();
                            // }}
                        >
                            Save
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Modal>
    </div>
  )
}

export {ApplyCitymanagerModal}