import React, { useState } from 'react'
import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import { ChangePassword, Fa2Security, ModuleTopHeading, ProfileSetting } from '../../components'

const { Title } = Typography

const Account = () => {
    const [ current, setCurrent ] = useState(0)

    const data = {
        head: [
            { item: 'Profile Setting' },
            { item: 'Change Password' },
            { item: '2FA Security' },
        ],
        contenttab: [
            { innercontent: <ProfileSetting /> },
            { innercontent:  <ChangePassword />},
            { innercontent: <Fa2Security /> },
        ]
    }

    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center'>
                            <ModuleTopHeading name='Account Settings' />
                            <Title level={5} className='m-0 text-white'>Owner name : Ahmed bilal</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex gap={'small'} className='scroll-mbl'>
                            {data?.head?.map((headtext, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setCurrent(index)}
                                    className={current === index ? 'btnactive btnpill' : 'btnpill'}
                                >
                                    {headtext?.item}
                                </Button>
                            ))}
                        </Flex>
                    </Col>
                    <Col span={24}>
                        {data?.contenttab[current]?.innercontent}
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { Account }
